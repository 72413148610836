import React, {useEffect, Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import GenerateDynamicMenu  from './GenerateDynamicMenu';
//import data from './data.json'

class Sidebar extends Component {

  constructor(){
    super();
    this.state = {
      menuData: [],
    }
  }
  

 
  onRouteChanged() {
  document.querySelector('#sidebar').classList.remove('active');
  Object.keys(this.state).forEach(i => {
    if(i!=null && i!=='' && i!==undefined && i!=='menuData')
    {
      this.setState({[i]: false});
    }
    
  });

  let dropdownPaths = [];
  let menuData=this.state.menuData;
  if(menuData!=='' && menuData!==null && menuData!==undefined )
  {
    if(menuData.length>0)
    {
      menuData.map(menu => {
        if(menu!=='' && menu!==null && menu!==undefined && menu.link==='#')
        {
          let jsonObj={path:menu.pathActive, state: menu.state};
          dropdownPaths.push(jsonObj);
        }
      });
    }
   
  }
  console.log('dropdownPaths::'+dropdownPaths);

  if(dropdownPaths!==null && dropdownPaths!=='' && dropdownPaths!==undefined && dropdownPaths.length>0)
  {
    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));
  }
}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  render () {
    
  return (
     
      <nav className="sidebar sidebar-offcanvas" id="sidebar">

        

        <div className="text-center sidebar-brand-wrapper d-flex align-items-center topMrg">
          {/*
          <a className="sidebar-brand brand-logo " href="/rdn/dashboard"><img src="/static/RW-Logo.png" alt="logo" /></a>
          */}
          <a className="sidebar-brand brand-logo " href="/rdn/dashboard">
            <div class="row">
                <div class="rw-logo-image col-2"></div>
                <div class="sidebar-item col-10">
                  <span class="right-data-normalizer right-data">RightData </span><span class="right-data-normalizer normalizer">Normalizer</span>
                </div>
            </div>
          </a>
          <a className="sidebar-brand brand-logo-mini pt-3" href="index.html"><img src="/static/RW-Mini-logo.png" alt="logo" /></a>
        </div>
        <ul id="nav" className="nav">
          {
          
          this.state.menuData.length > 0 ?
          <GenerateDynamicMenu menuData={this.state.menuData} isPathActive={(e) => {this.isPathActive(e)}}  />
          :''
          }
        </ul>
      </nav>
   
    );

    

  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
  
  populateMenuData() {
    
    console.log('rw: dataMenupopulateMenuData method called...');
    
    fetch('/adminuser/getClientMenuList/')
    .then((response) => response.json())
    .then(menuDataList => {
        console.log('RW: menu json obtained from api call:'+menuDataList.menus);
        if(menuDataList!==null && menuDataList.menus!==null && menuDataList.menus.length>0)
        {
          this.setState({ menuData: menuDataList.menus });
        }
        
    })
    .catch(err => console.error(err));

  }
  componentDidMount() {
    console.log('rw: componentDidMount method called...');
    
    this.populateMenuData();
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}


Sidebar.propTypes = {
  dataMenu: Array,
}



export default withRouter(Sidebar);