import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';

class Navbar extends Component {
  constructor(){
    super();
    this.state = {
      username: "",
      mode:"",
      today_date_from_test_parameter_table:"",
      current_user_role:"",
      final_user_role:'',
      first_name:"",
      last_name:"",
    }
  }


  componentDidMount() {
    fetch('/adminuser/getUsername')
        .then((response) =>  {
          if (response.status === 200)
          {
            response.json().then(res => {
              this.setState({username:res.username,first_name:res.first_name,last_name:res.last_name, mode:res.mode , today_date_from_test_parameter_table:res.today_date_from_test_parameter_table,current_user_role:res.current_user_role,final_user_role:res.final_user_role})
            })
          }
        })
        .catch(err => console.error(err));

  }




  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }

  logOut = () => {
    fetch('/wh/login/logout/')
        .then((response) => response.json())
        .then((response) => {
          console.log(`Response obtained from server after loggedout: ${response}`);
          if (response !== '' && response !== null && response !== undefined) {
            if (response.status === 'true') {
              localStorage.clear();
              window.location.href = response.link;
              // navigate(response.link, { replace: true });
            } else {
              window.location.href = '/404';
            }
          }
        })
        .catch((err) => { console.log(err); });
  };
  changePassHandler=()=>{
	  window.location.href = '/rdn/changepassword';
  };

  changeProfileHandler=()=>{
    window.location.href = '/rdn/profile';
  };

  changeMyPlanHandler=()=>{
    window.location.href = '/rdn/my-plan';
  };

  changeManageBillingHandler=()=>{
    window.location.href = '/rdn/manage-billing';
  };

  render () {
    return (
        <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
          <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
            <a className="navbar-brand rw-logo-image brand-logo-mini align-self-center d-lg-none" href="!#" onClick={evt =>evt.preventDefault()}></a>

            {this.state.mode === 'test' ?
                <p className="rw-gen-css rw-bold rw-test-mode" style={{marginLeft:'1rem'}}>Mode : {this.state.mode} , Today Date : {this.state.today_date_from_test_parameter_table} </p>
                :
            ''}
            
            <ul className="navbar-nav navbar-nav-right">
              <li className="nav-item  nav-profile border-0">
                
                <Dropdown>
                  {/*
                  <Dropdown.Toggle className="nav-link count-indicator bg-transparent"><span style={{color:"#76838f"}}><b className="rw-gen-css rw-bold">{this.state.username}</b><br /> (<b className="rw-gen-css rw-bold">{this.state.final_user_role}</b>)</span>
                    <img className="profile_icon img-xs rounded-circle ml-1" src="/static/profile_icon.png" alt="Profile" />
                  </Dropdown.Toggle>
                  */}
                  <Dropdown.Toggle className="nav-link count-indicator bg-transparent rw-tooltip">
                  <span><b className="rw-gen-css rw-bold">{`${this.state.first_name}`+" "+`${this.state.last_name}`}</b><img className="profile_icon img-xs rounded-circle ml-1" src="/static/profile_icon.png" alt="Profile" /></span>
                  <span className="rw-tooltiptext">
                    <b className="rw-gen-css rw-bold">{`${this.state.first_name}`+" "+`${this.state.last_name}`}</b> <br />
                    <b className="rw-gen-css rw-bold">{this.state.username}</b> <br />
                    <b className="rw-gen-css rw-bold">{this.state.final_user_role}</b>
                  </span>
                  </Dropdown.Toggle>
                  
                  <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                    <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={this.changeProfileHandler}>
                      <Trans>Profile Details</Trans>
                    </Dropdown.Item>

                    <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={this.changeMyPlanHandler}>
                      <Trans>My Plan</Trans>
                    </Dropdown.Item>

                    <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={this.changePassHandler}>
                      <Trans>Change Password</Trans>
                    </Dropdown.Item>

                    <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={this.logOut}>
                      <Trans>Sign Out</Trans>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>

            </ul>
            <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
              <span className="mdi mdi-menu"></span>
            </button>
          </div>
        </nav>
    );
  }
}

export default Navbar;
