import React, { Component } from 'react';
// import { Trans } from 'react-i18next';

class Footer extends Component {
  render () {
    return (

        <footer className="footer">
            <div className="container-fluid ">
                    <div class="text-center text-muted" style={{width:'100%'}}>RightWave, Inc. © 2022-2024</div>
                </div>

        </footer>
    );
  }
}

export default Footer;
